import { gql } from '@apollo/client';
import Fragemets from '../fragments';

export const USER_QUERY = gql`
  query getUser {
    me {
      ...UserInformation
      is_verified
      has_reviews
      created_at
      location {
        ...locationInfo
      }
    }
  }
  ${Fragemets.userInfo}
  ${Fragemets.locationInfo}
`;

export const RESOURCE_AUTHORIZATION_QUERY = gql`
  query can($input: AuthorizationInput!) {
    canAccess(input: $input)
  }
`;

export const PREMIUM_USER_QUERY = gql`
  query premiumUser($userId: String!) {
    premiumUser(userId: $userId) {
      id
      user {
        id
        name
        premium_user {
          id
          user {
            id
          }
          features
        }
      }
      features
    }
  }
`;

export const GET_SIDEBAR_STATUS = gql`
  {
    sidebarOpen @client
  }
`;

export const GET_APPLICATIONS_FILTER = gql`
  {
    applicationFilters @client {
      filters {
        q
        sort {
          field
          asc
        }
      }
      first
      page
    }
  }
`;

export const GET_DASHBOARD_FILTER = gql`
  {
    dashboardFilters @client {
      filters {
        q
        sort {
          field
          asc
        }
      }
      first
      page
    }
  }
`;

export const GET_REFUND_REQUESTS_FILTER = gql`
  {
    refundRequestsFilters @client {
      first
      page
    }
  }
`;

export const GET_APPLICATION_REVIEWS = gql`
  query applicationReviews($id: ID!, $first: Int!, $page: Int)
  {
    applicationReviews(id: $id, first: $first, page: $page) {
      data {
        rate
        user{
          id
          name
        }
      }
    }
  }
`;
